import * as React from "react";
import {
  Wrapper,
  PdpWrapper,
  DescriptionWrapper,
ArtistDescription,
  Citations,
  PhotosWrapper,
  CardWrapper,
  ProfilWrapper,
  NameWrapper,
  CarrouselWrapper2,
  LinkWrapper,
  ButtonWrapper,
  TitleWrapper,
  ArtistInfos,
  ImageWrapper,
  MobileProtrait,
  MoreButton,
  More,
} from "./Bando.module.css";
import Title from "./../../../../components/Title";
import Carousel from 'react-multi-carousel';

import { ListWrapper } from "../../Expositions/Expositions.module.css";
import EventCard from "../../../../components/EventCard";
import BandoPhoto1 from "../../../../res/bando/portrait.jpg"
import BandoPhoto3 from "../../../../res/Photos site/Bando/Bando-Tag-03.jpg"
import BandoPhoto4 from "../../../../res/Photos site/Bando/Bando-Tag-06.jpg"
import Button from "./../../../../components/Button";

const isBrowser = typeof window !== "undefined";
const infos ={
  path: "Bando",
  name: "Bando",
  description: "Philippe Lehman, dit Bando, est un graffiteur parisien des années 1980-1990 et un producteur de disques. Issu d'une famille aisée, il grandit à Paris. Il découvre le graffiti à New York grâce à Soy TWA et s’en influence grâce à des graffeurs comme Seen et Dondi White. Toute l’Europe passait par Paris, à partir de 1985, et le style de Bando, nourrit d’avantage par ses rencontres avec Mode2 et les TCA, venues de Londres, puis avec Shoe et les USA venus d’Amsterdam, s’affûtait pour établir sa réputation comme “Parrain du graffiti Européen”. Il fonde le crew des Bomb Squad 2 qui sera composé a son apogée de Boxer, Colt, Delta, Mode 2, Shoe. Bando a incité quelques artistes new-yorkais à venir à Paris tels que JonOne, qui avait auparavant rencontré la styliste parisienne Gigi à New York. Bando comptait parti les artistes représentés dans le musée du Graffiti à Paris, L'Aérosol - Maquis-art Hall of Fame organisé par maquis-art. L’une de ses expositions les plus importantes a été à la Pinacothèque de Paris en 2015, Les chefs-d’oeuvres du graffiti sur toile de Basquiat à Bando en 2015 par Alain-Dominique Galizzia.",

  pdp: BandoPhoto1,
  alt_pdp: "Photo de profil de Bando.",
  photos: [
    { src: BandoPhoto1, name: "Bando" },
    { src: BandoPhoto3, name: "Bando" },
    { src: BandoPhoto4, name: "Bando" },
  ],
  works: ["Works", "Works", "Works"],
  videos: ["Videos", "Videos", "Videos"],
  citations: []
};

const Bando = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      paritialVisibilityGutter: 0
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 0
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 0
    },
    mobile: {
      breakpoint: { max: 428, min: 0 },
      items: 1,
      paritialVisibilityGutter: 0
    },
  };
  return (
    <div className={Wrapper}>
      <div className={CardWrapper}>
        <div className={ProfilWrapper} id="f">
          <img className={PdpWrapper} src={infos.pdp} alt={infos.alt_pdp} />
        </div>
        <div className={ArtistDescription}>
          <h1 className={TitleWrapper}>BANDO <h2 style={{paddingLeft: "16px"}}>(Philippe Lehman - Franco-Américain)</h2></h1>
          <h3 className={NameWrapper} >Né en 1965</h3>
          <img className={MobileProtrait} src={infos.pdp} alt={infos.alt_pdp} />
          <div className={DescriptionWrapper} dangerouslySetInnerHTML={{__html:infos.description}}></div>
          <div  className={Citations}>
            {infos.citations.map((citations) => (
              <div>
                <p style={{fontStyle: "italic", textAlign: "center" }}>{citations}</p>
                <br />
              </div>
            ))}
          </div>
          <br />
        </div>
      </div>
      <br />

      <div className={PhotosWrapper}>
        <Title title={"Past or Present"} />
        <div className={CarrouselWrapper2}>
        <Carousel
            slidesToSlide={1}
            arrows={true}
            swipeable={true}
            draggable={true}
            responsive={responsive}
            infinite={false}
            customTransition="transform 500ms ease-in-out"
            transitionDuration={500}
            autoPlay={true}
            autoPlaySpeed={3000}
            showDots={false}
            partialVisbile={true}
            >
            {/* TODO: Faire les cartel ici */}
            {infos.photos.map((image) => (
                <img
                  className={ImageWrapper}
                  key={image.name}
                  alt={image.name}
                  src={image.src}
                />
            ))}
          </Carousel>
        </div>
      </div>
      <div className={ButtonWrapper}>
            <Button
              alt=""
              onClick={() => {
                if (typeof window !== "undefined") {
                  window.location.href = "/fr/contactez-nous";
                }
              }
            }
            >
              Contactez-nous
            </Button>
        </div>
    </div>
  );
};

export default Bando;