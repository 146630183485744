import * as React from "react";
import Layout from "./../../../components/fr/Layout";
import Bando from "./../../../nav/fr/Artistes/Bando";
import { Helmet } from "react-helmet";

const BandoPage = () => {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>BANDO | GHOST GALERIE</title>
        <meta
          name="description"
          content="GHOST galerie, Marseille. New York City : Post Graffiti • Old School | 80's & 90's"
        />
        <meta name="keywords" content="art,galerie,marseille" />
      </Helmet>
      <Bando />
    </Layout>
  );
};

export default BandoPage;
