// extracted by mini-css-extract-plugin
export var ArtistDescription = "Bando-module--ArtistDescription--9AFxg";
export var ArtistInfos = "Bando-module--ArtistInfos--Mw5yM";
export var ButtonWrapper = "Bando-module--ButtonWrapper --Hp3tI";
export var CardWrapper = "Bando-module--CardWrapper--pajgN";
export var CarrouselWrapper2 = "Bando-module--CarrouselWrapper2--etnua";
export var Citations = "Bando-module--Citations--0SuBL";
export var DescriptionWrapper = "Bando-module--DescriptionWrapper--mQjdh";
export var ImageWrapper = "Bando-module--ImageWrapper--e0GFu";
export var LinkWrapper = "Bando-module--LinkWrapper--3lPCr";
export var MobileProtrait = "Bando-module--MobileProtrait--3V2BW";
export var More = "Bando-module--More--D32eB";
export var MoreButton = "Bando-module--MoreButton--RXTFo";
export var NameWrapper = "Bando-module--NameWrapper--J+Jf6";
export var PdpWrapper = "Bando-module--PdpWrapper--saLhf";
export var PhotosWrapper = "Bando-module--PhotosWrapper--7nkzK";
export var ProfilWrapper = "Bando-module--ProfilWrapper--64sK0";
export var TitleWrapper = "Bando-module--TitleWrapper--DDT7p";
export var Wrapper = "Bando-module--Wrapper--6Lcg6";